<template>
  <el-checkbox
    class="checkbox-large"
    :indeterminate="checkAllStatus === 'indeterminate'"
    :disabled="total === 0"
    :value="checkAllStatus === 'all'"
    @change="checkAllChange"
    v-bind="$attrs"
    v-on="$listeners"
  >全选</el-checkbox>
</template>
<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    elTableInstance: {
      type: Object,
      default: () => ({})
    },
    tableData: {
      type: Array,
      default: () => ([])
    },
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    isPageChange: {
      type: Boolean,
      default: false
    },
    setIsPageChangeFalse: Function,
    getAllData: Function,
    selectionData: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      selectedAll: false,
      isIndeterminate: false,
      // 所有选择项
      selectionDataAll: {},
    }
  },
  computed: {
    selectionDataAllArr({ selectionDataAll }) {
      let tempArr = []
      for (const key in selectionDataAll) {
        tempArr = tempArr.concat(selectionDataAll[key])
      }
      this.$emit('selection-change', tempArr)
      return tempArr
    },
    checkAllStatus({ total, selectionDataAllArr, selectedAll }) {
      if (selectionDataAllArr.length < total && selectionDataAllArr.length > 0) return 'indeterminate'
      if (selectionDataAllArr.length === total || selectedAll) return 'all'
    }
  },
  watch: {
    value: {
      handler(n) {
        // console.log('value', n)
        this.selectedAllChange(n)
      },
      immediate: true
    },
    selectionData: {
      handler(n) {
        // 请求超过500全选失效
        // this.setIsPageChangeFalse()
        if (!this.isPageChange) {
          this.$set(this.selectionDataAll, this.page, n)
        }
        // console.log(this.selectionDataAll)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async checkAllChange(checked) {
      // console.log('checkAllChange', checked)
      this.selectedAllChange(checked)
      if (checked) {
        let allTableData = await this.getAllData()
        let { size } = this
        let selectionDataAll = {}
        let len = Math.ceil(allTableData.length / size)
        for (let i = 1; i <= len; i++) {
          selectionDataAll[i] = allTableData.slice((i - 1) * size, i * size)
        }
        this.selectionDataAll = selectionDataAll
        // console.log(selectionDataAll)
      }
      this.$emit('change', checked)
    },
    selectedAllChange(n) {
      // console.log(n)
      this.selectedAll = n
      let { elTableInstance } = this
      if (n) {
        if (this.selectionData.length < this.tableData.length) {
          this.$nextTick(function () {
            elTableInstance.toggleAllSelection && elTableInstance.toggleAllSelection()
          })
        }
      } else {
        this.selectionDataAll = {}
        this.$nextTick(function () {
          elTableInstance.clearSelection && elTableInstance.clearSelection()
        })
      }
    },
    toggleSelection(rows, selected) {
      let { tableData } = this
      if (rows) {
        rows.forEach(({ id }) => {
          let rowIndex = tableData.findIndex(row => row.id === id)
          if (rowIndex > -1) this.elTableInstance.toggleRowSelection(tableData[rowIndex], selected)
        })
      } else {
        this.elTableInstance.clearSelection()
      }
    },
    
    // 回显，表格改变时设置选中项
    initCallBack() {
      this.$nextTick(function () {
        if (this.isPageChange) {
          this.setIsPageChangeFalse()
          this.toggleSelection(this.selectionDataAll[this.page], true)
        } else {
          // console.log('initCallBack', false)
          this.selectedAllChange(false)
        }
      })
    }
  }
}
</script>
